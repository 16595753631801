const lich_lam_viec = [
    {
        label: 'Mã nhân viên',
        field: 'code',
        filterable: true,
        filterOptions: {
            enabled: true,
            placeholder: ""
        }
    },
    {
        label: 'Tên',
        field: 'name',
        filterable: true,
        filterOptions: {
            enabled: true,
            placeholder: ""
        }
    },
    {
        label: 'Sáng',
        field: 'morning',
        filterable: true,
        tdClass: 'text-center',
        filterOptions: {
            enabled: true,
            placeholder: "Nhập 1 hoặc 0",
        }
    },
    {
        label: 'Chiều',
        field: 'afternoon',
        filterable: true,
        tdClass: 'text-center',
        filterOptions: {
            enabled: true,
            placeholder: "Nhập 1 hoặc 0",
        }
    },
    {
        label: 'Tối',
        field: 'evening',
        filterable: true,
        tdClass: 'text-center',
        filterOptions: {
            enabled: true,
            placeholder: "Nhập 1 hoặc 0",
        }
    },
]
const so_ca_lead = [
    {
        label: 'Mã nhân viên',
        field: 'code',
        filterable: true,
        filterOptions: {
            enabled: true,
            placeholder: ""
        }
    },
    {
        label: 'Tên',
        field: 'name',
        filterable: true,
        filterOptions: {
            enabled: true,
            placeholder: ""
        }
    },
    {
        label: 'Sáng',
        field: 'morning',
        tdClass: 'text-center',
        filterable: true,
        filterOptions: {
            enabled: true,
            placeholder: ""
        }
    },
    {
        label: 'Chiều',
        field: 'afternoon',
        tdClass: 'text-center',
        filterable: true,
        filterOptions: {
            enabled: true,
            placeholder: ""
        }
    },
    {
        label: 'Tối',
        field: 'evening',
        tdClass: 'text-center',
        filterable: true,
        filterOptions: {
            enabled: true,
            placeholder: ""
        }
    },
    {
        label: 'Văn phòng',
        field: 'branch',
        filterable: true,
        filterOptions: {
            enabled: true,
            placeholder: ""
        }
    },
]
const lich_hen = [
    {
        label: 'Tên khách hàng',
        field: 'name',
        filterable: true,
        filterOptions: {
            enabled: true,
            placeholder: ""
        }
    },
    {
        label: 'Số điện thoại',
        field: 'phone',
        filterable: true,
        filterOptions: {
            enabled: true,
            placeholder: ""
        }
    },
    {
        label: 'Loại lịch hẹn',
        field: 'nguon',
        filterable: true,
        filterOptions: {
            enabled: true,
            placeholder: ""
        }
    },
    {
        label: 'Lịch hẹn',
        field: 'lich_hen_tu_van',
        filterable: true,
        filterOptions: {
            enabled: true,
            placeholder: ""
        }
    },
    {
        label: 'Người giới thiệu',
        field: 'nguoi_gt',
        filterable: true,
        filterOptions: {
            enabled: true,
            placeholder: ""
        }
    },
]
const lich_hen_huy = [
    {
        label: 'Tên khách hàng',
        field: 'name',
        filterable: true,
        filterOptions: {
            enabled: true,
            placeholder: ""
        }
    },
    {
        label: 'Số điện thoại',
        field: 'phone',
        filterable: true,
        filterOptions: {
            enabled: true,
            placeholder: ""
        }
    },
    {
        label: 'Loại lịch hẹn',
        field: 'nguon',
        filterable: true,
        filterOptions: {
            enabled: true,
            placeholder: ""
        }
    },
    {
        label: 'Lịch hẹn',
        field: 'ngay_hen',
        filterable: true,
        filterOptions: {
            enabled: true,
            placeholder: ""
        }
    },
    {
        label: 'Người giới thiệu',
        field: 'nguoi_gt',
        filterable: true,
        filterOptions: {
            enabled: true,
            placeholder: ""
        }
    },
]
const kh_test = [
    {
        label: 'Tên khách hàng',
        field: 'name',
        filterable: true,
        filterOptions: {
            enabled: true,
            placeholder: ""
        }
    },
    {
        label: 'Số điện thoại',
        field: 'phone',
        filterable: true,
        filterOptions: {
            enabled: true,
            placeholder: ""
        }
    },
    {
        label: 'Nguồn',
        field: 'nguon',
        filterable: true,
        filterOptions: {
            enabled: true,
            placeholder: ""
        }
    },
    {
        label: 'Lịch hẹn',
        field: 'ngay_hen',
        filterable: true,
        filterOptions: {
            enabled: true,
            placeholder: ""
        }
    },
    {
        label: 'Thời gian khách hàng đến',
        field: 'created_at',
        filterable: true,
        filterOptions: {
            enabled: true,
            placeholder: ""
        }
    },
    {
        label: 'Người giới thiệu',
        field: 'nguoi_gt',
        filterable: true,
        filterOptions: {
            enabled: true,
            placeholder: ""
        }
    },
    {
        label: 'Tư vấn viên',
        field: 'tu_van_vien',
        filterable: true,
        filterOptions: {
            enabled: true,
            placeholder: ""
        }
    },
]
const quyen_loi = [
    {
        label: 'Mã HV',
        field: 'code',
        filterable: true,
        filterOptions: {
            enabled: true,
            placeholder: ""
        }
    },
    {
        label: 'Tên HV',
        field: 'name',
        filterable: true,
        filterOptions: {
            enabled: true,
            placeholder: ""
        }
    },
    {
        label: 'Số tiền',
        field: 'amount_coefficient',
        filterable: true,
        filterOptions: {
            enabled: true,
            placeholder: ""
        }
    },
    {
        label: 'Người giới thiệu',
        field: 'nguoi_gt',
        filterable: true,
        filterOptions: {
            enabled: true,
            placeholder: ""
        }
    },
    {
        label: 'Đội SL',
        field: 'doi_sl',
        filterable: true,
        filterOptions: {
            enabled: true,
            placeholder: ""
        }
    },
]
const quyen_loi_chi_nhanh = [
    {
        label: 'Mã HV',
        field: 'code',
        filterable: true,
        filterOptions: {
            enabled: true,
            placeholder: ""
        }
    },
    {
        label: 'Tên HV',
        field: 'name',
        filterable: true,
        filterOptions: {
            enabled: true,
            placeholder: ""
        }
    },
    {
        label: 'Số tiền',
        field: 'amount_installment',
        filterable: true,
        filterOptions: {
            enabled: true,
            placeholder: ""
        }
    },
    {
        label: 'Người giới thiệu',
        field: 'nguoi_gt',
        filterable: true,
        filterOptions: {
            enabled: true,
            placeholder: ""
        }
    },
    {
        label: 'Đội SL',
        field: 'doi_sl',
        filterable: true,
        filterOptions: {
            enabled: true,
            placeholder: ""
        }
    },
]

export const BAO_CAO_TYPE = {
    'REGISTER_WORK'     : 'dk_lam',
    'REAL_WORK'         : 'thuc_te_lam',
    'LEAD_NUMBER'       : 'so_ca_lead',

    'CALENDAR'          : 'lich_hen',
    'CANCEL_CALENDAR'   : 'lich_hen_huy',
    'CUSTOMER_TEST'     : 'kh_test',

    'BENEFIT_CUSTOMER'  : 'quyen_loi',
    'DEBT_CONTRACT'     : 'hop_dong_no',

    'NEW_REVENUE'       : 'doanh_so_khach_hang_moi',
    'NEW_CONTRACT'      : 'hop_dong_moi',
    'INTERNAL_CONTRACT' : 'hop_dong_noi_bo',
    'REFUND'            : 'hoan_tien',
    'WITHDRAW_MONEY'   : 'rut_tien',
}
export const BAO_CAO_CONFIG = {
    "dk_lam" : {
        "title" : "Danh sách nhân sự đăng ký đi làm",
        "columns": lich_lam_viec
    },
    "thuc_te_lam" : {
        "title" : "Danh sách nhân sự thực tế đi làm",
        "columns": lich_lam_viec
    },
    "so_ca_lead" : {
        "title" : "Số ca Lead/ngày",
        "columns": so_ca_lead
    },
    "lich_hen" : {
        "title" : "Danh sách khách hàng có lịch hẹn",
        "columns": lich_hen
    },
    "lich_hen_huy" : {
        "title" : "Danh sách khách hàng có lịch hẹn bị hủy",
        "columns": lich_hen_huy
    },
    "kh_test" : {
        "title" : "Danh sách khách hàng lên test",
        "columns": kh_test
    },
    "quyen_loi" : {
        "title" : "Danh sách quyền lợi",
        "columns": quyen_loi
    },
    "hop_dong_no" : {
        "title" : "Danh sách hợp đồng nợ",
        "columns": quyen_loi
    },
    "doanh_so_khach_hang_moi" : {
        "title" : "Doanh số khách hàng mới",
        "columns": quyen_loi
    },
    "hop_dong_moi" : {
        "title" : "Danh sách hợp đồng mới",
        "columns": quyen_loi
    },
    "hop_dong_noi_bo" : {
        "title" : "Danh sách hợp đồng nội bộ",
        "columns": quyen_loi
    },
    "hoan_tien" : {
        "title" : "Danh sách hợp đồng nợ",
        "columns": quyen_loi
    },
    "rut_tien" : {
        "title" : "Danh sách rút tiền",
        "columns": quyen_loi
    },
};
export const BAO_CAO_CHI_NHANH = {
    "so_ca_lead" : {
        "title" : "Số ca Lead/ngày",
        "columns": so_ca_lead
    },
    "lich_hen" : {
        "title" : "Danh sách khách hàng có lịch hẹn",
        "columns": lich_hen
    },
    "lich_hen_huy" : {
        "title" : "Danh sách khách hàng có lịch hẹn bị hủy",
        "columns": lich_hen_huy
    },
    "kh_test" : {
        "title" : "Danh sách khách hàng lên test",
        "columns": kh_test
    },
    "quyen_loi" : {
        "title" : "Danh sách quyền lợi",
        "columns": quyen_loi_chi_nhanh
    },
    "hop_dong_no" : {
        "title" : "Danh sách hợp đồng nợ",
        "columns": quyen_loi_chi_nhanh
    },
    "doanh_so_khach_hang_moi" : {
        "title" : "Doanh số khách hàng mới",
        "columns": quyen_loi_chi_nhanh
    },
    "hop_dong_moi" : {
        "title" : "Danh sách hợp đồng mới",
        "columns": quyen_loi_chi_nhanh
    },
    "hop_dong_noi_bo" : {
        "title" : "Danh sách hợp đồng nội bộ",
        "columns": quyen_loi_chi_nhanh
    },
    "hoan_tien" : {
        "title" : "Danh sách hợp đồng nợ",
        "columns": quyen_loi_chi_nhanh
    },
    "rut_tien" : {
        "title" : "Danh sách rút tiền",
        "columns": quyen_loi_chi_nhanh
    },
};
