<template>
<div class="row">
    <div class="col-xl-12">
        <div id="panel-1" class="panel">
            <div class="panel-container show">
                <div class="panel-content">
                    <el-row :gutter="10">
                        <el-col :lg="4" :md="8" :sm="12" :xl="4" :xs="24">
                            <edutalk-date-picker
                                class="w-100 mt-3"
                                v-model="cache_date_range"
                                type="daterange"
                                popper-class="edutalk-range no-feature"
                                ref="picker"
                                @changeMonth="onchangeMonth"
                                @change="changeRangeDate"
                                @changeYear="onchangeMonth"
                                :picker-options="pickerOptions"
                                :unlink-panels="true"
                                range-separator="-"
                                start-placeholder="Từ ngày"
                                end-placeholder="Đến ngày">
                            </edutalk-date-picker>
                        </el-col>
                        <el-col v-if="isGDKV" :lg="4" :md="8" :sm="24" :xl="3" :xs="24">
                            <el-select v-model="cache_branch" class="w-100 mt-3" clearable filterable placeholder="Chọn văn phòng để xem" @change="handleChangeBranch">
                                <el-option
                                    v-for="item in branches"
                                    :key="item.id"
                                    :label="item.name_alias ? item.name_alias : item.name"
                                    :value="item.id"
                                    placeholder="Chọn cơ sở">
                                    <span style="float: left">{{ item.name_alias ? item.name_alias : item.name }}</span>
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col v-if="(isGDKV && cache_branch > 0) || isGDVP || childUsers.length > 0" :lg="5" :md="8" :sm="12" :xl="3" :xs="24">
                            <tree-select v-model="cache_sale" :normalizer=normalizer
                                     :options="options"
                                     :show-count="true" class="w-100 mt-3"
                                     placeholder="Chọn nhân sự muốn xem..."
                                     search-nested
                                     @input="handleChangeSale" >
                                <!--eslint-disable-->
                                <label slot="option-label"
                                       slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }"
                                       :class="labelClassName">
                                    <img :src="publicPath + getImageHierarchy(node.raw.profile.account_type.id)" alt="">
                                    <span> {{ node.label }} </span>
                                    <span v-if="shouldShowCount" :class="countClassName">({{ count }})</span>
                                </label>
                            </tree-select>
                        </el-col>
                        <el-col v-if="!isGDKV || (isGDKV && query.sale)" :lg="isGDKV ? 4 : 5" :md="8" :sm="12" :xl="3"
                                :xs="24">
                            <el-select v-model="cache_view_as" class="w-100 mt-3" clearable  @change="handleChangeViewAs"
                                       placeholder="Chọn tư cách để xem">
                                <el-option
                                    v-for="item in watchAs"
                                    :key="item.id"
                                    :label="item.value"
                                    :value="item.id">
                                    <span style="float: left">{{ item.value }}</span>
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col :lg="3" :md="8" :sm="24" :xl="2" :xs="24">
                            <button :disabled="loading" class="btn btn-primary w-100 mt-3" @click="submit"><i
                                v-bind:class="[loading ? 'el-icon-loading' : 'el-icon-search']"></i> Tìm kiếm
                            </button>
                        </el-col>
                    </el-row>
                    <el-row v-loading="loadingReport" class="mt-5">
                        <div class="table-bao-cao-kinh-doanh">
                            <table class="table table-hover table-bordered table-responsive">
                                <thead>
                                <tr>
                                    <th class="width-title header">Ngày trong tháng</th>
                                    <th class="font-size-10 text-center" width="100px">KQ tháng</th>
                                    <th v-for="(value) in tableDate" class="font-size-10 text-center">{{ value }}</th>
                                </tr>
                                </thead>
                                <tbody>

                                <kd-item :data="dataReport['so_dang_ky_lich_lam']" :table-date="tableDate" :type="BAO_CAO_TYPE.REGISTER_WORK"
                                         calculate="number" class-name="unset-border"
                                         title="Số lượng ca đăng kí đi làm" @detail="openDetail"></kd-item>

                                <kd-item :data="dataReport['so_dang_ky_lich_lam']" :table-date="tableDate" :type="BAO_CAO_TYPE.REAL_WORK"
                                         calculate="attendance" title="Số lượng công thực tế đi làm"
                                         @detail="openDetail"></kd-item>

                                <kd-item :data="dataReport['so_ca_lead_trong_ngay']" :table-date="tableDate" :type="BAO_CAO_TYPE.LEAD_NUMBER"
                                         calculate="number" title="Số ca Lead/ngày"
                                         @detail="openDetail"></kd-item>

                                <kd-item :data="dataReport['lich_hen_du_kien']" :table-date="tableDate" :type="BAO_CAO_TYPE.CALENDAR"
                                         calculate="number"
                                         title="Số lịch hẹn" @detail="openDetail"></kd-item>

                                <kd-item :data="dataReport['lich_hen_huy_trong_ngay']" :table-date="tableDate" :type="BAO_CAO_TYPE.CANCEL_CALENDAR"
                                         calculate="number"
                                         title="Số lịch hẹn bị hủy"
                                         @detail="openDetail"></kd-item>

                                <kd-item :data="dataReport['kh_len_test']" :table-date="tableDate" :type="BAO_CAO_TYPE.CUSTOMER_TEST"
                                         calculate="number"
                                         title="Số lượng KH lên test" @detail="openDetail"></kd-item>

                                <kd-item :data="dataReport['quyen_loi']" :table-date="tableDate" :type="BAO_CAO_TYPE.BENEFIT_CUSTOMER"
                                         calculate="total" title="Số quyền lợi"
                                         tooltip="Lần đầu đóng dưới 1200k" @detail="openDetail"></kd-item>

                                <kd-item :data="dataReport['quyen_loi']"
                                         :table-date="tableDate" calculate="amount" title="Quyền lợi"
                                         tooltip="Doanh số lần đầu đóng dưới 1200k"></kd-item>

                                <kd-item :data="dataReport['hop_dong_no']" :table-date="tableDate" :type="BAO_CAO_TYPE.DEBT_CONTRACT"
                                         calculate="total" title="Số lượng hợp đồng nợ"
                                         tooltip="Hợp đồng công nợ"
                                         @detail="openDetail"></kd-item>

                                <kd-item :data="dataReport['hop_dong_no']" :table-date="tableDate" calculate="amount"
                                         title="Doanh số nợ" tooltip="Doanh số hợp đồng công nợ"></kd-item>

                                <kd-item :data="dataReport['hop_dong_moi']" :table-date="tableDate" :type="BAO_CAO_TYPE.NEW_CONTRACT"
                                         calculate="total" title="Số lượng hợp đồng mới"
                                         tooltip="Lần đầu đóng từ 1200k trở lên"
                                         @detail="openDetail"></kd-item>
                                <kd-item :data="dataReport['hop_dong_moi']" :table-date="tableDate" :type="BAO_CAO_TYPE.NEW_REVENUE"
                                         calculate="amount" title="Doanh số Khách hàng mới"
                                         tooltip="Doanh số khách hàng mới đóng lần thứ 2 trở đi trong tháng"
                                         @detail="openDetail"></kd-item>

                                <kd-item :data="{
'hop_dong_moi': dataReport['hop_dong_moi'],
'kh_len_test': dataReport['kh_len_test'],
'hop_dong_noi_bo': dataReport['hop_dong_noi_bo']
}" :table-date="tableDate" special="percent" title="Tỷ lệ chốt"
                                         tooltip="(Số HĐ mới + Số HĐ nội bộ) / Tổng Số KH lên test"></kd-item>

                                <kd-item :data="dataReport['hop_dong_noi_bo']" :table-date="tableDate"
                                         :type="BAO_CAO_TYPE.INTERNAL_CONTRACT"
                                         calculate="total" title="Số lượng hợp đồng nội bộ"
                                         tooltip="Số lượng HĐ có [Mã giới thiệu] = [Mã nhân sự]"
                                         @detail="openDetail"></kd-item>
                                <kd-item :data="dataReport['hop_dong_noi_bo']" :table-date="tableDate"
                                         calculate="amount"
                                         title="Doanh số nội bộ"
                                         tooltip="Doanh số hợp đồng có [Mã giới thiệu] = [Mã nhân sự]"></kd-item>

                                <kd-item :data="{
                                    'quyen_loi': dataReport['quyen_loi'],
                                    'hop_dong_no': dataReport['hop_dong_no'],
                                    'hop_dong_moi': dataReport['hop_dong_moi'],
                                    'hop_dong_noi_bo': dataReport['hop_dong_noi_bo'],
                                    'rut_tien': dataReport['rut_tien'],
                                    }" :table-date="tableDate" special="total" title="Doanh số key"
                                tooltip="Doanh số key = Quyền lợi + Doanh số KH mới + Doanh số nợ + Doanh số nội bộ - Rút tiền key - Hoàn tiền key"></kd-item>

                                <kd-item :data="{'DS_digital': dataReport['DS_digital']}" 
                                                special="digital" :table-date="tableDate" title="Doanh số Digital"
                                                tooltip="Doanh số Digital = Nạp- Rút - Hoàn Digital"></kd-item>
                                <kd-item :data="{
                                'quyen_loi': dataReport['quyen_loi'],
                                'hop_dong_moi': dataReport['hop_dong_moi'],
                                'DS_digital': dataReport['DS_digital'],
                                'hop_dong_no': dataReport['hop_dong_no'],
                                'hop_dong_noi_bo': dataReport['hop_dong_noi_bo'],
                                'rut_tien': dataReport['rut_tien'],
                                }" :table-date="tableDate" special="total" title="Tổng doanh số"
                                tooltip="Tổng doanh số = Doanh số key + Doanh số Digital">
                                </kd-item>

                                <kd-item :data="dataReport['rut_tien']" :table-date="tableDate"
                                         :type="BAO_CAO_TYPE.WITHDRAW_MONEY" calculate="withdraw"
                                         title="Số tiền rút"></kd-item>

                                <kd-item :data="dataReport['rut_tien']" :table-date="tableDate" :type="BAO_CAO_TYPE.REFUND"
                                         calculate="refund"
                                         title="Số tiền hoàn" @detail="openDetail"></kd-item>
                                </tbody>
                            </table>
                        </div>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
    <el-dialog :title="dialog.title" class="responsive" :visible.sync="dialog.active">
        <el-row v-loading="dialog.loading" :gutter="10" class="pr-2 pl-2">
            <vue-good-table
                    :columns="columns"
                    :line-numbers="true"
                    :pageChanged="onPageChange"
                    :pagination-options="{
                    enabled: rows.length > 10,
                    perPage: 10,
                    setCurrentPage: 1,
                }"
                :rows="rows"
                styleClass="vgt-table table m-0 table-bordered table-hover table-striped">
                <template slot="pagination-bottom" slot-scope="props">
                    <edutalk-paginate
                        :pageChanged="props.pageChanged"
                        :paging="props"
                        :perPageChanged="props.perPageChanged"
                        :total="props.total">
                    </edutalk-paginate>
                </template>
                <template slot="table-row" slot-scope="props">
                    <span v-if="viewCircleCheck(props.column.field)">
                        <a v-if="props.formattedRow[props.column.field] == 1" class="btn btn-primary btn-sm btn-icon rounded-circle waves-effect waves-themed"  href="javascript:void(0);">
                            <i class="fal fa-check"></i>
                        </a>
                    </span>
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>
        </el-row>
    </el-dialog>
</div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {REPORT_KINH_DOANH_DOI_CHI_TIET_V2, REPORT_KINH_DOANH_DOI_V2} from "@/core/services/store/report.module";
import {BRANCH_LIST, HIERARCHY} from "@/core/services/store/service.module";
import EdutalkPaginate from "../edutalk/edutalk-paginate";
import {BAO_CAO_CONFIG, BAO_CAO_TYPE} from '@/core/config/baocao-config';
import {GDKV, GDVP, HANH_CHINH_KHU_VUC} from "@/core/config/accountTypeOption";
import lodash from 'lodash-es';
import deepdash from 'deepdash-es';
import KdItem from "@/views/pages/report/kinh-doanh-doi-item-v2";
import {mapGetters} from "vuex";
import {GET_CHILD_USER} from "../../../core/services/store/service.module";

const _ = deepdash(lodash);
export default {
    name: "kinh-doanh-doi",
    components: {
        EdutalkPaginate,
        KdItem
    },
    data() {
        return {
            publicPath: process.env.VUE_APP_BASE_URL,
            GDVP,
            HANH_CHINH_KHU_VUC,
            GDKV,
            imageHierarchy: {
                '5': 'media/hierarchy/gdvp.png',
                '1': 'media/hierarchy/sl.png',
                '2': 'media/hierarchy/se.png',
                '3': 'media/hierarchy/s.png',
                '4': 'media/hierarchy/si.png'
            },
            watchAs: [
                {
                    id: "1",
                    value: 'Đội kinh doanh',
                    label: 'Đội'
                },
                {
                    id: "2",
                    value: 'Cá nhân',
                    label: 'Cá nhân'
                }
            ],
            columns: [
                {
                    label: 'Name',
                    field: 'name',
                    filterable: true,
                    filterOptions: {
                        enabled: true,
                        placeholder: ""
                    }
                },
                {
                    label: 'Age',
                    field: 'age',
                    type: 'number',
                    filterable: true,
                    filterOptions: {
                        enabled: true,
                        placeholder: ""
                    }
                },
                {
                    label: 'Created On',
                    field: 'createdAt',
                    type: 'date',
                    dateInputFormat: 'yyyy-MM-dd',
                    dateOutputFormat: 'MMM do yy',
                    filterable: true,
                    filterOptions: {
                        enabled: true,
                        placeholder: ""
                    }
                },
                {
                    label: 'Percent',
                    field: 'score',
                    type: 'percentage',
                    filterable: true,
                    filterOptions: {
                        enabled: true,
                        placeholder: ""
                    }
                },
            ],
            pickerOptions: {
                disabledDate: this.disabledDate,
                onPick: this.onPick
            },
            BAO_CAO_TYPE: BAO_CAO_TYPE,

            //  cache
            cache_date_range: '',
            cache_branch: '',
            cache_sale: null,
            cache_view_as: 1,
            currentDate: new Date(),

            //loading
            loading: false,
            loadingReport: false,

            isGDKV: false,
            isGDVP: false,
            options: [],
            childUsers: [],

            query: {
                startDate: '',
                endDate: '',
                branch_id: '',
                area_id: '',
                sale: '',
                is_level: false
            },
            form: {
                start_date: '',
                branch_id: '',
                area_id: '',
                view_as: '',
                sale: '',
            },
            branches: [],

            dataReport: [],
            tableDate: [],
            dialog: {
                active: false,
                title: null,
                loading: false
            },

            rows: [],
            onPageChange: this.handleOnPageChange,
        }
    },
    created() {
        this.isBusy = true;
        this.setParams();
    },
    computed: {
        ...mapGetters(['currentUser'])
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Danh sách"},
            {title: "Kinh doanh đội", icon: 'far fa-money-bill'}
        ]);
        if (this.currentUser.profile.account_type_id === this.GDKV) {
            this.isGDKV = true;
            this.getCenter();
            this.getHierarchy();
        } else {
            if (_.indexOf(['GĐVP'], this.currentUser.profile.account_type.name) > -1 ||
                _.indexOf(['TPOnline', 'TPOL', 'TPTT'], this.currentUser.user_positions) > -1 ) {
              this.isGDVP = true;
                this.query.branch_id = this.currentUser.profile.chi_nhanh_id;
                this.handleChangeBranch();
                this.watchAs.unshift({
                    id: 0,
                    value: 'TPhòng/Giám đốc',
                    label: 'TP/GĐ'
                });
            } else {
                this.$store.dispatch(GET_CHILD_USER).then((res) => {
                    this.childUsers = this.options = res.data;
                })
            }
            this.currentDate = new Date();
            this.query.view_as = null;
        }

    },
    methods: {

        /**
         * TODO Handle Change Selecter
         */
        changeRangeDate() {
            let minDate = this.$moment(this.cache_date_range[0]).format('YYYY-MM-DD');
            let maxDate = this.$moment(this.cache_date_range[1]).format('YYYY-MM-DD');
            this.query.startDate = minDate
            this.query.endDate = maxDate
            this.pushParams();
        },

        handleChangeBranch() {
            this.query.branch_id = this.cache_branch;
            let params = {}
            if (this.cache_branch > 0) {
                params = { branch_id: this.cache_branch }
                this.getHierarchy(params);
            } else {
                this.query.sale = null;
                this.query.view_as = null;
                params = { branch_id: this.currentUser.profile.chi_nhanh_id }
                if (_.indexOf(['GĐVP'], this.currentUser.profile.account_type.name) > -1 || _.indexOf(['TPOnline', 'TPOL', 'TPTT'], this.currentUser.user_positions) > -1) {
                    this.isGDVP = true;
                    this.query.branch_id = this.currentUser.profile.chi_nhanh_id;
                    if (this.query.branch_id)
                        this.getHierarchy(params);
                } else {
                    this.getChildUser()
                }
            }
            this.query.sale = null
            this.cache_sale = this.query.sale
            this.pushParams();
        },

        handleChangeSale() {
            if (!this.cache_sale) {
                this.cache_view_as = null
            }
            this.query.sale = this.cache_sale;
            this.query.view_as = this.cache_view_as
            this.pushParams();
        },

        handleChangeViewAs() {
            this.query.view_as = this.cache_view_as
            this.pushParams();
        },

        onchangeMonth(item) {
            let date = new Date(item.date);
            let year = date.getFullYear(), month = date.getMonth();
            let startOfMonth = new Date(year, month, 1);
            let endOfMonth = new Date(year, month + 1, 0);

            let minDate = this.$moment(startOfMonth).format('YYYY-MM-DD')
            let maxDate = this.$moment(endOfMonth).format('YYYY-MM-DD');
            this.query.startDate = minDate
            this.query.endDate = maxDate
            this.cache_date_range = [startOfMonth, endOfMonth];
            this.handleChangeBranch()
        },
        /**
         * TODO: Init Function
         * @returns {Promise<void>}
         */
        setParams: async function () {
            let date = new Date();
            let year = date.getFullYear(), month = date.getMonth();
            let startOfMonth = new Date(year, month, 1);
            let endOfMonth = new Date(year, month + 1, 0);

            let minDate = this.$moment(startOfMonth).format('YYYY-MM-DD')
            let maxDate = this.$moment(endOfMonth).format('YYYY-MM-DD');
            this.query.startDate = this.$route.query.startDate ?? minDate
            this.query.endDate = this.$route.query.endDate ?? maxDate
            this.cache_date_range = [this.query.startDate, this.query.endDate];
            this.changeTable();

            this.query.branch_id = this.$route.query.branch_id ?? (this.currentUser.profile.chi_nhanh_id ?? null)
            this.cache_branch = this.query.branch_id;

            this.query.sale = this.$route.query.sale ?? null
            this.cache_sale = this.query.sale;

            this.query.view_as = this.$route.query.view_as ?? "1"
            this.cache_view_as = this.query.view_as;
            this.querySever();
        },

        customPageChange(customCurrentPage) {
            this.pageChanged({currentPage: customCurrentPage});
        },

        customPerPageChange(customPerPage) {
            this.perPageChanged({currentPerPage: customPerPage});
        },

        disabledDate(date) {
            let endOfCurrentMonth = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);
            let endMonth = this.endOfMonth > endOfCurrentMonth ? endOfCurrentMonth : this.endOfMonth;
            if (this.minDate) {
                return this.startOfMonth > date || date > endMonth;
            }
            return date > endOfCurrentMonth;
        },

        onPick({maxDate, minDate}) {
            this.minDate = minDate;
            this.maxDate = maxDate;
            if (this.minDate) {
                let date = new Date(this.minDate);
                let year = date.getFullYear(), month = date.getMonth();
                this.startOfMonth = new Date(year, month, 1);
                this.endOfMonth = new Date(year, month + 1, 0);
            }
        },

        saveRange() {
            this.query.start_date = item;
        },

        pushParams: function () {
            this.$router.push({path: '/report/kinh-doanh-doi', query: this.query}).catch(()=> {});
        },

        changeTable() {
            let tableDate = [];
            let maxDate = this.$moment(this.cache_date_range[1]);
            this.yearSelection = this.$moment(this.cache_date_range[1]).format('YYYY');
            for (let i = 0; i < 31; i++) {
                let date = this.$moment(this.cache_date_range[0]).add(i, 'days');
                if (date <= maxDate) {
                    tableDate.push(date.format('DD/MM'))
                }
            }
            this.tableDate = tableDate;
        },

        getImageHierarchy(account_type) {
            return this.imageHierarchy[account_type];
        },

        submit() {
            this.changeTable();
            this.querySever();
        },
        /**
         * TODO: Call API
         */
        getCenter() {
            this.$store.dispatch(BRANCH_LIST)
                .then((response) => {
                    if (response.status === 422) {
                        this.$message({type: 'warning', message: `${response.message}`, showClose: true});
                    } else {
                        this.branches = response.data;
                        if (this.isGDKV) {
                            this.branches.unshift({id: 0, name: 'Xem toàn khu vực'});
                        }
                        let branch_id = this.$route.query.branch_id ? this.$route.query.branch_id : 0;
                        this.cache_branch = _.find(this.branches, function (branch) {
                            return parseInt(branch.id) === parseInt(branch_id);
                        }).id ?? 0;
                    }
                })
        },

        getHierarchy() {
            let params = {};
            if (this.query.branch_id > 0) {
                params = _.merge(params, {branch_id: this.query.branch_id })
            } else {
                params = _.merge(params, {branch_id: this.currentUser.profile.chi_nhanh_id })
            }
            if (this.query.startDate) {
                params = _.merge(params, {viewDate: this.$moment(this.query.startDate).format('YYYY-MM')})
            }
            this.$store.dispatch(HIERARCHY, params)
                .then((response) => {
                    this.options = response.data;
                })
        },

        getChildUser() {
            this.$store.dispatch(GET_CHILD_USER).then((res) => {
                if (this.currentUser.profile.account_type.name == 'GĐKV') {
                  this.childUsers = [];
                  return;
                }
                this.childUsers = this.options = res.data;
            })
        },

        querySever() {
            this.loading = true;
            this.loadingReport = true;
            this.query.is_level = 0;
            if(!this.query.view_as) {
                this.query.is_level = 1;
            }
            this.$store.dispatch(REPORT_KINH_DOANH_DOI_V2, this.query)
                .then((response) => {
                    if (response.status === 422) {
                        this.$message({type: 'warning', message: `${response.message}`, showClose: true});
                    } else {
                        this.dataReport = response;
                    }
                    this.loading = false;
                    this.loadingReport = false;
                })
        },

        openDetail(item) {
            if (item.number !== 0) {
                this.dialog.active = true
                this.dialog.loading = true
                let config = BAO_CAO_CONFIG[item.type]
                this.columns = config.columns
                this.dialog.title = config.title;
                let params = {
                    type: item.type,
                    date: `${item.day}/${this.yearSelection}`,
                    idCreated: this.dataReport.created,
                    branch_id: this.query.branch_id,
                    view_as: this.query.view_as 
                }
                this.rows = []
                this.$store.dispatch(REPORT_KINH_DOANH_DOI_CHI_TIET_V2, params)
                    .then((response) => {
                        if (response.status === 422) {
                            this.$message({type: 'warning', message: `${response.message}`, showClose: true});
                        } else {
                            this.rows = response.data;
                        }
                        this.dialog.loading = false
                    })
            }
        },

        viewCircleCheck(field) {
            return _.indexOf(['morning', 'afternoon', 'evening'], field) > -1
        },

        handleOnPageChange(event) {
            this.currentPage = event
        },
        normalizer: function (node) {
            return {
                id: node.id,
                label: node.name,
            }
        },
    },

}
</script>
