<template>
  <div class="frame-wrap mt-3">
    <nav aria-label="Page navigation example">
      <ul class="pagination justify-content-end">
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
          <a class="page-link" href="#" tabindex="-1" aria-disabled="true" @click="customPageChange(currentPage - 1)">Previous</a>
        </li>
        <li v-for="(n, k) in Math.ceil(total/10)" :key="k" class="page-item cursor-pointer" :class="{ active: currentPage === n }" aria-current="page">
          <span class="page-link"  @click="customPageChange(n)" > {{ n }}  <span class="sr-only">(current)</span>
          </span>
        </li>
        <li class="page-item" :class="{ disabled: currentPage === Math.ceil(total/10) }">
          <a class="page-link" href="#" @click="customPageChange(currentPage + 1)">Next</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: "edutalk-paginate",
  props: {
    total: {
      type: Number,
    },
    paging: {
      type: Object,
    },
    pageChanged: {
      type: Function,
    },
    perPageChanged: {
      type: Function,
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  watch: {
    total: {
      handler(newValue) {
        if(newValue) {
          this.currentPage = 1;
          this.customPageChange(1);
        }
      }
    }
  },
  methods: {
    customPageChange(customCurrentPage) {
      this.currentPage = customCurrentPage;
      this.pageChanged({ currentPage: customCurrentPage });
    },
    customPerPageChange(customPerPage) {
      this.perPageChanged({currentPerPage: customPerPage});
    }
  }
}
</script>